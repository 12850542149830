import React, { useState } from 'react'
import az_new_paper from "../../assets/images/az_new_paper.png";
import img from "../../assets/images/azlogomessage.jpeg";
import { Link } from 'react-router-dom';
import { object, string } from 'yup';
import { addNewsLetter } from '../../services/AuthServices';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

const FooterDesign = () => {
    const [isDisable, setisDisable] = useState(false);
  
   const AdminSchema = object().shape({
     email: string()
       .required("Please enter email.")
       .email("Invalid Email Format. Please try again.")
       .trim("Please enter email."),

     name: string()
       .required("Please enter Your Name.")
       .trim("Please enter Your Name."),
   });

   const submitClicked = async (data, resetForm) => {
     setisDisable(true);
     let formdata = new FormData();
     formdata.append("email", values?.email);
     formdata.append("name", values?.name);

     await addNewsLetter(formdata)
       .then((response) => {
         toast("News Letter added Successfully", { type: "success" });
         setisDisable(false);
         resetForm();
       })
       .catch((error) => {
         setisDisable(false);
         if (error?.response?.status == 422)
           toast(error?.response?.data?.message, { type: "error" });
         else if (error?.response?.status == 500)
           toast(error?.response?.data?.message, { type: "error" });
         else
           toast(error?.response?.data?.message || "Something went wrong", {
             type: "error",
           });
       });
   };

   const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
     useFormik({
       validationSchema: AdminSchema,

       initialValues: {
         email: "",
         name: "",
       },
       onSubmit: async (values, { resetForm }) => {
         submitClicked(values, resetForm);
       },

       onReset: () => {},
     });
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <h2>Categories</h2>
              {/* <ul>
                <li>
                  <Link to="/" className="active">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="#">Events</Link>
                </li>
                <li>
                  <Link to="#">Classifieds</Link>
                </li>
                <li>
                  <Link to="#">Businesses</Link>
                </li>
                <li>
                  <Link to="#">Doctor</Link>
                </li>
                <li>
                  <Link to="#">Services</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="#">Organization</Link>
                </li>
                <li>
                  <Link to="#">Contact Us</Link>
                </li>
                <li>
                  <Link to="#">Our Story</Link>
                </li>
                <li>
                  <Link to="#">Advertise with us</Link>
                </li>
                <li>
                  <Link to="#">Privacy Policy</Link>
                </li>
              </ul> */}
              <ul>
                {" "}
                <li>
                  <Link to="/">Home</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/calender">Events</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/classifield">Classifieds</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/local-bussiness">Businesses</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/doctor">Doctor</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/services">Services</Link>{" "}
                </li>{" "}
              </ul>{" "}
              <ul>
                {" "}
                <li>
                  <Link to="/organization">Organization</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/Contact">Contact Us</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/about">Our Story</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/ads-information">Advertise with us</Link>{" "}
                </li>{" "}
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>{" "}
                </li>{" "}
              </ul>
            </div>
            <div className="col-sm-3">
              <h2>Newsletter</h2>
              <div className="col-sm-12">
                <div className="test_field">
                  {" "}
                  <i className="far fa-user" />
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    id="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{
                      borderColor: touched?.name && errors?.name && "red",
                      margin: touched?.name && errors?.name && "0px",
                    }}
                  />
                  {touched?.name && errors?.name && (
                    <span className="text-danger">{errors.name}</span>
                  )}
                </div>
                <div className="test_field">
                  {" "}
                  <i className="far fa-envelope" />
                  <input
                    placeholder="Enter your email"
                    type="email"
                    name="email"
                    id="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{
                      borderColor: touched?.email && errors?.email && "red",
                      margin: touched?.email && errors?.email && "0px",
                    }}
                  />
                  {touched?.email && errors?.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </div>
                <button type="submit" onClick={handleSubmit}>
                  <i className="far fa-paper-plane" /> Subscribe Now
                </button>
              </div>
            </div>
            <div className="col-sm-3">
              <h2>News</h2>
              <div className="col-sm-12">
                {" "}
                <a href="http://www.azindiatimes.com/" target="_blank">
                  <img src={az_new_paper} alt="azindia new paper" />
                </a>{" "}
              </div>
            </div>
            <div className="col-sm-3">
              {" "}
              <Link to="/">
                <img
                  src={"/img/azindia_logo.svg"}
                  alt="az india"
                  className="azflogo"
                />
              </Link>
              <p>
                © AZIndia.com 1999-2024 AZIndia.com is not affiliated to any
                businesses that are listed or advertised on this site and will
                not be liable for any products/services aquired from them.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p>
                <span style={{ color: "#ff6820" }}>AZIndia © .</span>
                All rights reserved. Developed by{" "}
                <a
                  // style={{ color: "#ff6820" }}
                  style={{ color: "white",textDecoration:'underline' }}
                  href={`https://wa.me/+919376002929`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  N2N Solutions with ❤️.
                </a>
              </p>
            </div>
            <div className="col-sm-6">
              <div className="fs_icons">
                {" "}
                <a href="https://www.instagram.com/" target="_blank">
                  <i className="fab fa-instagram" aria-hidden="true" />
                </a>{" "}
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="https://twitter.com/" target="_blank">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a href="https://linkedin.com/" target="_blank">
                  <i className="fab fa-linkedin-in" aria-hidden="true" />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterDesign