import React, { useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideADModule from "../../containers/PostCards/SideADModule";
import { getJobsData, getJobsSubCatData } from "../../services/HomeServices";
import {
  formatDate,
  formatDateToMMYYYY,
  plainString,
} from "../../lib/constant";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "../../containers/Loader";
import { useNavigate } from "react-router-dom";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
    const [selectedCat, setselectedCat] = useState();
  const [links, setlinks] = useState([]);
  const [catList, setcatList] = useState([]);

  useEffect(() => {
    getData();
  }, [ page]);

    useEffect(() => {
      getData();
      setpage(1)
    }, [search, selectedMonth,selectedCat]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getCategory();
  }, []);

  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 700;

  const getData = async () => {
    setisLoading(true);
    await getJobsData(search, formatDateToMMYYYY(selectedMonth), page,selectedCat)
      .then((response) => {
        setdata(response?.data?.data?.data);
        setisLoading(false);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

    const getCategory = async () => {
      await getJobsSubCatData()
        .then((response) => {
          setcatList(response?.data?.data);
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div
              className="binduz-er-top-news-title"
              style={{
                display: widthOfPage ? "flex" : "",
                justifyContent: "space-between",
              }}
            >
              <h3 className="binduz-er-title">
                <i
                  className="far fa-briefcase"
                  style={{ color: "#FF6821", marginRight: "5px" }}
                ></i>
                JOBSBOARD
              </h3>
              <a
                className="classifield-text mt-2 mb-3 "
                style={{
                  height: "40px",
                  // marginLeft: "25px",
                  display: "flex",
                  // marginRight: "43px",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/jobs-post");
                }}
              >
                {"POST A JOB >>"}
              </a>
            </div>
            {/* <p>Opportunities from Local Businesses, for Local Talent.</p> */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="date-picker">
                    <ReactDatePicker
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      autoComplete="off"
                      placeholderText="Select Month & Year"
                      selected={selectedMonth}
                      onChange={(e) => {
                        setselectedMonth(e);
                      }}
                      name="date"
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="binduz-er-select ">
                    <select
                      placeholder="Data"
                      value={selectedCat}
                      onChange={(e) => setselectedCat(e?.target?.value)}
                    >
                      <option value={""}>Select Category</option>
                      {catList?.map((x, i) => (
                        <option value={x?.id}>{x?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((item, index) => (
                      <CardRowModule
                        key={item.id}
                        datas={{
                          data: { ...item, isAzTimes: true },
                          image: item?.image,
                          category: item?.title,
                          index: index,
                          isTopStories: true,
                          isJobs: true,
                          pathname: `/jobs-detail/${item?.id}`,
                          title: plainString(item?.description),
                          onClickFun: () => {
                            navigate(`/jobs-detail/${item?.id}`, {
                              state: item,
                            });
                          },
                          date: formatDate(item?.date),
                        }}
                      />
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Data found</h5>
                )}
              </>
            ) : (
              <div style={{ marginTop: "80px" }}>
                <Loader />
              </div>
            )}
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <SideADModule isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
